<!-- 忘记密码 -->
<template>
  <div class="forgetPassword">
    <div class="login-box">
      <div class="login-box-left">
        <div class="applogo-box">
          <img src="@/static/dzb.png" class="applogo" />
          <span class="applogo-text">店掌宝·会员收银</span>
        </div>
        <div class="ft-60 text-left color-fff mg-lt100">
          <div>Hi，您好！</div>
          <div class="mg-tp30">欢迎进入店掌宝会员收银系统</div>
        </div>
      </div>
      <div class="index">
        <div class="title">找回密码</div>
        <div class="input_box">
          <img src="@/static/phone.png" alt="" class="input_img" />
          <input
            type="number"
            placeholder="请输入您的手机号"
            class="input_index"
            v-model="phone"
            style="flex: 1;"
          />
          <span v-if="time == 0" class="captcha" @click="getCode()">验证码</span>
          <span class="captcha" v-else>{{ time }}s</span>
        </div>
        <div class="input_box">
          <img src="@/static/captcha.png" alt="" class="input_img" />
          <input
            type="number"
            placeholder="请输入验证码"
            class="input_index"
            v-model="captcha"
          />
        </div>
        <div class="input_box">
          <img src="@/static/password.png" alt="" class="input_img" />
          <input
            type="password"
            placeholder="请输入您的新密码"
            class="input_index"
            v-model="newPassword"
            min="6|date"
            maxlength="12"
          />
        </div>
        <div class="input_box">
          <img src="@/static/password.png" alt="" class="input_img" />
          <input
            type="password"
            placeholder="请再次输入您的新密码"
            class="input_index"
            v-model="againNewPassword"
          />
        </div>
        <div class="btn-con">
          <van-button class="btn" style="background: #EAEAEA; color: #666666;" @click="$router.back()">
            取消
          </van-button>
          <van-button class="btn" :disabled="isSubmit" @click="submit()">
            保存
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "forgetPassword",
  data() {
    return {
      time: 0, //倒计时
      phone: "", //手机号
      captcha: "", //验证码
      newPassword: "", //新密码
      againNewPassword: "", //再次输入新密码
    };
  },
  computed: {
    isSubmit() {
      return (
        !this.phone &&
        !this.captcha &&
        !this.newPassword &&
        !this.againNewPassword
      );
    },
  },
  methods: {
    // 发送验证码
    getCode() {
      const self = this;
      self.time = 30;
      var timer = setInterval(() => {
        var time = self.time - 1;
        console.log(time);
        self.time = time;
        if (self.time <= 0) {
          self.time = 0;
          clearInterval(timer);
        }
      }, 1000);
    },
    // 保存成功后跳转至登录页
    submit() {
      // Toast.setDefaultOptions({ duration: 500 }); //设置toast显示时长
      Toast.setDefaultOptions("loading", { forbidClick: true }); //设置toast不可点击
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (this.phone == "") {
        Toast("手机号不能为空");
      } else if (!reg_tel.test(this.phone)) {
        Toast("请输入正确的手机号");
      } else if (this.captcha == "") {
        Toast("验证码不能为空");
      } else if (this.newPassword == "" || this.againNewPassword == "") {
        Toast("新密码不能为空");
      } else if (this.newPassword.length < 6) {
        Toast("密码不能低于6位数");
      } else if (this.againNewPassword != this.newPassword) {
        Toast("两次输入的密码不相同");
      } else {
        Toast("修改密码成功");
        const toast = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "修改成功,即将跳转至首页 ",
        });
        let second = 4;
        const timer = setInterval(() => {
          second--;
          if (second) {
            toast.message = `倒计时 ${second} 秒`;
          } else {
            clearInterval(timer);
            // 手动清除 Toast
            Toast.clear();
            this.$router.push({ path: "/", query: {} });
          }
        }, 1000);
      }
    },
  },
};
</script>

<style scoped lang='less'>
.captcha {
  font-size: 2.4rem;
  font-weight: 500;
  color: #1588F5;
}
.forgetPassword {
  width: 100%;
  height: 100%;
  background-image: url("../../static/bg3.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.login-box {
  width: 100%;
  height: 100%;
  background: rgba(20, 136, 245, 0.4);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .login-box-left {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
    .applogo-box {
      position: absolute;
      top: 6rem;
      left: 10rem;
      display: flex;
      align-items: center;
      .applogo {
        width: 8rem;
        height: 8rem;
        margin-right: 2.3rem;
        border-radius: 0.8rem;
      }
      .applogo-text {
        font-size: 3rem;
        color: #fff;
        font-weight: 700;
      }
    }
  }
}
.index {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  border-radius: 0.8rem;
  margin-right: 10rem;
  padding: 4rem 7rem;
  max-height: 83vh;
  overflow-y: scroll;
}
.heard {
  width: 14rem;
  height: 14rem;
  border-radius: 50%;
  margin-bottom: 3rem;
  margin-top: 5rem;
}
.title {
  width: 36rem;
  height: 5.6rem;
  font-size: 4rem;
  font-weight: 500;
  color: #1588F5;
  line-height: 5.6rem;
  margin-bottom: 4.9rem;
}
.input_box {
  width: 52rem;
  height: 8rem;
  background: #ffffff;
  border-radius: 4.6rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  font-size: 2.6rem;
  border: 0.1rem solid #C9C9C9;
  padding-right: 3rem;
}
.input_img {
  width: 3.4rem;
  height: 3.4rem;
  margin-left: 4rem;
  margin-right: 3.5rem;
}
.input_index {
  width: 35rem;
  height: 7rem;
  color: #333;
}
.forget {
  width: 70rem;
  text-align: end;
  font-size: 2.4rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 3.3rem;
  margin-top: 2.4rem;
}
.btn {
  border: none;
  width: 25rem;
  height: 8rem;
  border: none;
  border-radius: 4.6rem;
  font-size: 2.8rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 8rem;
  margin-top: 3.5rem;
  background: #1588F5;
}
/deep/ .van-button--disabled {
  opacity: 0.8;
}
.btn-con {
  width: 55rem;
  display: flex;
  justify-content: space-between;
}
</style>>

